<template>
	<div class="page" v-loading="loading" element-loading-text="正在打印" element-loading-spinner="el-icon-loading"
		element-loading-background="rgba(0, 0, 0, 0.5)" customClass="myloading">
		<div class="container">

			<div class="con">

				<div class="box1">


					<div>
						<div class="shops" style=" height:620px">
							<div class="goods" v-for="(item,index) in list" :key="index">
								<div class="item">
									<router-link :to="'/pdf?url='+$domain+item.file_url">
										<div class="pic">pdf</div>
									</router-link>
									<div class="title">{{ item.title }}</div>
									<div class="info-box">
										<div class="info">
											<!-- 			<div class='printnum'>
												<i class="iconfont icon-dayinji_o"></i>
												<span>{{ item.download_count }}</span>
											</div>
											<div class='printnum'>
												<i class="iconfont icon-dayinji_o"></i>
												<span>{{ item.download_count }}</span>
											</div> -->
											<div class="price">¥ {{ item.money }}</div>
										</div>

									</div>
									<div class="btn" @click="buyOpen(item)">立即打印</div>
								</div>

								<el-dialog :visible.sync="dialogVisible" width="30%" :before-close="handleClose">

									<div style="text-align: center;">


										<div v-if="selectNum">
											<div style='font-weight: bold; line-height: 50px;'>请选择您要购买的数量</div>
											<el-input-number v-model="num" @change="handleChange" :min="1" :max="10"
												label="描述文字"></el-input-number>
											<el-button type="primary" style='margin-left:15px'
												@click="selectNum = false">确认
											</el-button>
										</div>


										<div style='margin-top:15px;line-height: 50px;' v-else>
											<div style='font-weight: bold;'>请选择支付方式</div>
											<el-button type="success" @click="buy(currObj)">微信支付</el-button>
											<el-button type="danger" @click="balancePayment(currObj,1)"
												v-if="$store.state.userinfo">余额支付</el-button>

											<el-button type="danger" @click="balancePayment(currObj,2)"
												v-if="$store.state.userinfo">企业支付</el-button>

											<div style='margin-top:15px'>
												<vue-qr v-if="showcode" :text="code" :size="200"></vue-qr>
											</div>
										</div>

									</div>

								</el-dialog>

							</div>
							<div class="clear" style="float:clear"></div>
						</div>

						<div style="margin-top: 20px;">
							<el-pagination @current-change="handleCurrentChange" :current-page.sync="query.page"
								:page-size="query.limit" layout="total, prev, pager, next" :total="total">
							</el-pagination>
						</div>
					</div>

				</div>
				<div class="box2">
					<div class="cate">
						<div class="category">
							<!-- 	<div @click="clickCate(item.id)" v-for="(item,index) in cates" :key="index">
								<span>{{ item.name }}</span>
							</div> -->

							<el-tree :data="cates" :props="defaultProps" @node-click="handleNodeClick"
								:default-expand-all="true"></el-tree>
						</div>
					</div>

				</div>
			</div>
		</div>


		<el-dialog :visible.sync="printOk" width="30%">
			<div class="info" style="text-align: center;">
				<p class='iconfont icon-chenggong' style="font-size: 100px; color:#67C23A"></p>
				<p style="color:#67C23A; font-size: 50px; line-height: 100px;">打印成功</p>

				<div v-if="$store.state.userinfo">
					<p style="color:red">为确保您的信息安全，打印完成后记得退出</p>
					<p>
						<el-button type="danger" style="width:80%" @click="loginout()">退出</el-button>
					</p>
					<p>
						<el-button type="primary" style="width:80%; margin:30px 0" @click="printOk=false">继续打印
						</el-button>
					</p>
				</div>
			</div>
		</el-dialog>


	<Print ref="print"></Print>

	</div>


</template>

<script>
	import vueQr from 'vue-qr'
	import Print from "@/components/Print.vue"
	
	import {
		getLodop
	} from '../../utils/LodopFuncs'
	export default {
		components: {
			vueQr,
			Print
		},
		data() {
			return {
				defaultProps: {
					children: 'child',
					label: 'name'
				},

				cates: [],
				list: [],
				total: 0,
				dialogVisible: false,
				dialogVisible2: true,
				num: 1,
				timer: null,
				query: {
					page: '1',
					limit: 12,
					title: '',
					cid: '',
				},
				code: '',
				showcode: false,
				selectNum: true,

				currObj: null,
				timer: null,
				jobCode: '1', //job码
				printStatus: 1, //打印状态
				loading: false,
				printOk: false,
				devList: {}
			}
		},
		created() {
			this.shopCateList();
			this.shopList();
		},
		methods: {


			handleClose() {
				clearTimeout(this.timer);
				this.dialogVisible = false
			},


			async shopCateList() {
				let res = await this.$api.shopCateChildList();

				this.cates = res.data;

			},
			async shopList() {
				let res = await this.$api.shopList(this.query);
				console.log('res1', res);
				this.list = res.data.rows;
				this.total = res.data.page_info.total;
			},

			handleCurrentChange(e) {
				console.log(e)
				this.query.page = e;
				this.shopList()
			},
			buyOpen(item) {
				console.log('当前参数', item)

				this.dialogVisible = true;
				this.currObj = item;
				this.num = 1;
				this.selectNum = true;
				this.showcode = false;
				this.code = '';
			},

			clickCate(id) {

				this.query.cid = id;
				this.query.page = 0;
				this.shopList();
			},

			handleNodeClick(obj) {
				console.log('obj', obj)
				this.clickCate(obj.id)
			},


			//微信支付
			async buy(obj) {
				console.log('wx支付参数', obj)
				let data = {
					print_count: obj.page,
					shop_id: obj.id,
					type: 3,
					print_number: this.num
				}



				if (this.$store.state.userinfo) {


					let res = await this.$api.scanPay(data);
					this.code = res.data.code_url;
					this.showcode = true;


					this.timer = setInterval(() => {
						this.scanPaySuccessful(res.data.order_number, res.data.task_number);
					}, 1000)



				} else {
					let res = await this.$api.ykscanPay(data);
					this.code = res.data.code_url;
					this.showcode = true;


					this.timer = setInterval(() => {
						this.ykscanPaySuccessful(res.data.order_number, res.data.task_number);
					}, 1000)

				}




			},

			//余额支付
			async balancePayment(obj, type) {
				clearTimeout(this.timer);

				let that = this;
				let data = {
					ctype: "1",
					type: '1',
					task_type: "1",
					money_type: 'A4_DOUBLE_FACE_COLOUR',
					paper_type: "2",
					print_type: '2',
					print_ctype: "1",
					shop_id: obj.id,
					task_type: "1",
					print_number: this.num,
					print_file: 'aa',
					print_hb_file: 'aa',
					print_typeset:'1'

				}
				let res = await this.$api.balancePayment(data);
				if (res.code == 200) {
					this.$message.success('购买成功，立即打印')
					this.dialogVisible = false;


				/* 	let data = {
						'print_file': that.$domain + that.currObj.file_url,
						'print_ctype': 1,
						'task_number': res.task_number
					} */
					data.print_file =  that.currObj.file_url;
					data.task_number = res.task_number;
					
					
					
					console.log('开始打印11')


					this.printFile(data);


				} else {
					this.$message.error(res.msg)
				}

				console.log('res', res)


			},


			async scanPaySuccessful(order, task_number) {
				let that = this;
				let res = await this.$api.scanPaySuccessful({
					'order_number': order
				});
				console.log(res);
				if (res.data == "SUCCESS") {
					clearTimeout(this.timer);
					this.dialogVisible = false;


					let data = {
						'print_file': that.$domain + that.currObj.file_url,
						'print_ctype': 1,
						'task_number': task_number
					}

					this.printFile(data);


					clearTimeout(this.timer)
				}
			},


			async ykscanPaySuccessful(order, task_number) {
				let that = this;
				let res = await this.$api.ykscanPaySuccessful({
					'order_number': order
				});
				console.log(res);
				if (res.data == "SUCCESS") {
					clearTimeout(this.timer);
					this.dialogVisible = false;


					let data = {
						'print_file': that.currObj.file_url,
						'print_ctype': 1,
						'task_number': task_number
					}

					this.printFile(data);


					clearTimeout(this.timer)
				}
			},





			printFile(obj) {
				console.log('abc',obj);
				this.$refs.print.order(obj, 'printfile')
				
				return false;
				
				
				
				console.log('开始打印')
				let LODOP = getLodop();


				this.loading = true;
				console.log('aaa', this.loading)
				let self = this;

				LODOP.PRINT_INIT("测试PDF打印功能");
				LODOP.SET_PRINTER_INDEXA(self.$store.state.devList['EPSON WF-C5290 Series']);
				
				
				
				if (localStorage.getItem('model') == '1') {
					
				
					LODOP.SET_PRINTER_INDEXA(this.$store.state.devList['EPSON WF-C5290 Series']);
					LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4 210 x 297 毫米');
				} else {
					LODOP.SET_PRINTER_INDEXA(self.$store.state.devList['iR-ADV C5235']);
				
					LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4');
				
				
				}
				
				
				
				
				

				console.log(2233, this.demoDownloadPDF(obj.print_file))

				LODOP.ADD_PRINT_PDF(0, 0, "100%", "100%", this.demoDownloadPDF(obj.print_file));
				if (obj.print_ctype == 2) { //双面打印
					LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 2);
				}

				LODOP.SET_PRINT_COPIES(1); //打印份数
				//LODOP.SET_PRINT_STYLE('FontColor','#000')
				LODOP.SET_PRINT_STYLEA(0, "FontColor", "#808080");

				LODOP.SET_PRINT_MODE("CATCH_PRINT_STATUS", true);

				LODOP.On_Return = function(TaskID, Value) {
					console.log("TaskID:" + TaskID);
					console.log("Value:" + Value); //job代码
					self.jobCode = Value;
					var timer = setInterval(function() {
						console.log("每次轮询的状态：" + self.printStatus);
						console.log('轮询bb', self.loading)
						if (self.printStatus != 1) { //打印成功
							clearInterval(timer);
							self.printStatus = 1;
							self.loading = false;

							self.open();

							let data = {
								'task_number': obj.task_number,
								'device_number': localStorage.getItem('device')
							};
							self.accomplish(data);

							return;
						}
						self.getStatusValue(Value);
					}, 1000);
				};
				//LODOP.PRINT();
				LODOP.PRINT();
				/* let data = {
					'task_number': obj.task_number,
					'device_number': localStorage.getItem('device')
				};
				this.accomplish(data); */

				return;


			},


			async accomplish(data) {

				let res = await this.$api.accomplish(data);
				console.log(111);
				//	this.loading = false;
				//this.TaskList();
			},

			open() {
				this.printOk = true;

				if (this.$store.state.userinfo) {
					//this.$router.go(0)
				} else {
					setTimeout(() => {
						this.$router.push('/RegisterVip')
					}, 3000)

				}

			},

			loginout() {
				sessionStorage.setItem('token', '');
				sessionStorage.setItem('userInfo', null);
				this.$store.commit('setUserinfo', null);
				this.$message.success('用户退出成功');
				this.$router.push('/');
			},

			getStatusValue(job) { //根据job代码，获取是否打印成功

				var self = this;
				LODOP = getLodop();
				LODOP.On_Return = function(TaskID, Value) {
					console.log("TaskID:" + TaskID);
					console.log("打印成功状态:" + Value); //打印成功状态
					self.printStatus = Value;
				};
				LODOP.GET_VALUE("PRINT_STATUS_EXIST", job);
			},



			demoDownloadPDF(url) {
				if (!(/^https?:/i.test(url))) return;
				if (window.XMLHttpRequest) var xhr = new XMLHttpRequest();
				else var xhr = new ActiveXObject("MSXML2.XMLHTTP");
				xhr.open('GET', url, false); //同步方式
				if (xhr.overrideMimeType)
					try {
						xhr.responseType = 'arraybuffer';
						var arrybuffer = true;
					} catch (err) {
						xhr.overrideMimeType('text/plain; charset=x-user-defined');
					}
				xhr.send(null);
				var data = xhr.response || xhr.responseBody;
				if (typeof Uint8Array !== 'undefined') {
					if (arrybuffer) var dataArray = new Uint8Array(data);
					else {
						var dataArray = new Uint8Array(data.length);
						for (var i = 0; i < dataArray.length; i++) {
							dataArray[i] = data.charCodeAt(i);
						}
					}
				} else
					var dataArray = VBS_BinaryToArray(data).toArray(); //兼容IE低版本
				return this.demoGetBASE64(dataArray);
			},

			demoGetBASE64(dataArray) {
				var digits = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
				var strData = "";
				for (var i = 0, ii = dataArray.length; i < ii; i += 3) {
					if (isNaN(dataArray[i])) break;
					var b1 = dataArray[i] & 0xFF,
						b2 = dataArray[i + 1] & 0xFF,
						b3 = dataArray[i + 2] & 0xFF;
					var d1 = b1 >> 2,
						d2 = ((b1 & 3) << 4) | (b2 >> 4);
					var d3 = i + 1 < ii ? ((b2 & 0xF) << 2) | (b3 >> 6) : 64;
					var d4 = i + 2 < ii ? (b3 & 0x3F) : 64;
					strData += digits.substring(d1, d1 + 1) + digits.substring(d2, d2 + 1) + digits.substring(d3, d3 + 1) +
						digits.substring(d4, d4 + 1);
				}
				return strData;
			},





		}



	}
</script>

<style lang="scss" scoped>
	.page {
		width: 100%;
		background-color: #fff;
	}

	.con {
		margin-top: 30px;
		width: 100%;
		height: 80vh;
		background: #fff;
		display: flex;
		justify-content: space-between;

		.box1 {
			flex: 5;

			.goods {
				width: 150px;
				padding: 5px;
				float: left;
				margin: 15px;
				color: #888;

				.pic {
					width: 140px;
					height: 140px;
					padding: 15px;
					color: #fff;
					font-size: 20px;
					font-weight: bold;
					background: #FFC069;
					cursor: pointer;
				}

				.title {
					font-size: 16px;
				}

				.iconfont {
					font-size: 20px;
				}

				.price {
					color: red;
					font-weight: bold;
				}
			}


			.btn {
				display: block;
				width: 80px;
				height: 20px;
				background-color: #5CDBD3;
				color: #fff;
				text-align: center;
				line-height: 20px;
				margin-top: 10px;
				cursor: pointer;
			}

		}

		.box2 {
			flex: 1;
			/*  background-color: yellow; */

		}

		.info-box {
			display: flex;
			justify-content: space-between;
		}
	}


	.el-dialog__body {
		text-align: center;
	}

	.box2 .cate {
		height: 60vh;
		padding: 15px;
		box-shadow: 1px 1px 10px #ccc;
		margin-top: 15px;
	}

	.category span {
		line-height: 35px;
	}

	.category span:hover {
		cursor: pointer;
		color: red;
	}
</style>
